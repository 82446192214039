import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const BlockAccess = () => {
  return (
    <Container>
      <Row className="justify-content-md-center align-items-center" style={{ height: '100vh' }}>
        <Col md="auto">
          <h2>This website is not available in your country.</h2>
        </Col>
      </Row>
    </Container>
  );
};

export default BlockAccess;
