import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import styles from '../styles/Navbar.module.scss';
import merckLogo from '../assets/images/logos/merck-logo.svg';

const Navigation = () => {
  return (
    <Navbar collapseOnSelect expand='lg' bg='light' variant='light' id='home'>
      <Container fluid>
        <Navbar.Brand href='/' className='bold' style={{ fontSize: '22px' }}>
          Global Health Innovation Fund
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse
          className='justify-content-end'
          id='responsive-navbar-nav'
        >
          <Nav>
            <img
              className={styles.merckLogo}
              src={merckLogo}
              alt='Merck logo'
              target='_blank'
              width={'100px'}
            />
            <Nav.Link style={{ marginTop: '4px' }} href='/#investment-focus'>
              Investment Focus
            </Nav.Link>
            <Nav.Link style={{ marginTop: '4px' }} href='/#portfolio'>
              Portfolio
            </Nav.Link>
            <Nav.Link style={{ marginTop: '4px' }} href='/#ghif-distinction'>
              The GHIF Distinction
            </Nav.Link>
            <Nav.Link style={{ marginTop: '4px' }} href='/#our-team'>
              Our Team
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
