import { useEffect, useState, useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './pages/Main';
import Alumni from './pages/Alumni';
import BlockAccess from './pages/BlockAccess';
import Layout from './components/Layout';

const App = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const profile = queryParams.get('profile');
  const investment = queryParams.get('investment');

  const introRef = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {
    // Existing scroll logic
    setTimeout(() => {
      const id = window.location.hash.slice(1);
      const el = document.getElementById(id);
      if (el) {
        el.scrollIntoView();
      }
    }, 200);

    // Function to inject a Geotargetly script
    const scriptVersion = process.env.REACT_APP_SCRIPT_VERSION;
    let scriptID, scriptSrc;

    if (scriptVersion === 'staging') {
      scriptID = 'georedirect1712173960012';
      scriptSrc = 'https://g10498469755.co/gr?id=-Nu_WJgKgemTMElCrqaA&refurl=' + document.referrer + '&winurl=' + encodeURIComponent(window.location);
    } else if (scriptVersion === 'production') {
      scriptID = 'georedirect1712174136737';
      scriptSrc = 'https://g10498469755.co/gr?id=-Nu_WzpkkqMRuxR78UTe&refurl=' + document.referrer + '&winurl=' + encodeURIComponent(window.location);
    } else {
      // If the environment variable is not set or is set to something else,
      // you might want to handle it differently or load a default script.
      console.warn('No valid script version set in REACT_APP_SCRIPT_VERSION');
      return;
    }

    const styleID = `${scriptID}style`;
    document.head.insertAdjacentHTML('afterbegin', `<style id="${styleID}">body{opacity:0.0 !important;}</style>`);

    const script = document.createElement('script');
    script.async = true;
    script.src = scriptSrc;
    script.onerror = function() {
      document.getElementById(styleID).innerHTML = 'body{opacity:1.0 !important;}';
    };
    document.head.appendChild(script);

    window[`${scriptID}loaded`] = function(redirect) {
      var to = 0;
      if (redirect) { to = 5000; }
      setTimeout(function() {
        document.getElementById(styleID).innerHTML = 'body{opacity:1.0 !important;}';
      }, to);
    };

    // Cleanup function
    return () => {
      if (document.head.contains(script)) {
        document.head.removeChild(script);
      }
      const styleEl = document.getElementById(styleID);
      if (styleEl) document.head.removeChild(styleEl);
    };

  }, []);

  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Main profile={profile} investment={investment} introRef={introRef} footerRef={footerRef} />} />
            <Route path='alumni' element={<Alumni />} />
          </Route>
          <Route path='/blockaccess' element={<BlockAccess />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
