import React from 'react';
import Col from 'react-bootstrap/Col';
import styles from '../styles/PortfolioTile.module.scss';

const PortfolioTile = ({ item }) => {
  return (
    <Col xl={2} lg={3} md={4} sm={6} xs={12} className={styles.portfolioTile}>
      <img src={item.image} alt={`${item.name} logo`} />
      <a
        className={styles.layoverWrapper}
        href={item.link}
        target='_blank'
        rel='noreferrer'
        aria-label='Opens in a new tab.'
      >
        <p>{item.description}</p>
      </a>
    </Col>
  );
};

export default PortfolioTile;
