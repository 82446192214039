import { useState, useEffect } from 'react';

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      // options can be added here if needed, for example: { rootMargin: '0px' }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    // Clean up the observer on component unmount or ref change
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
      observer.disconnect();
    };
  }, [ref]); // Ensure useEffect re-runs if `ref` changes

  return isIntersecting;
}
