import digitalHealthImg from '../assets/images/digital-health-acumen.jpg';
import ventureMentalityImg from '../assets/images/venture-mentality.jpg';
import corporatePlatformImg from '../assets/images/corporate-platform.jpg';

const ghifDistinction = [
  {
    id: 0,
    name: 'Digital Health Acumen',
    image: digitalHealthImg,
    body: [
      'Recognized leader in corporate venture capital and digital health investing',
      'Team with extensive expertise in healthcare, data and analytics, life sciences',
      'Focused investment model that is theraputic-and-brand-agnostic'
    ]
  },
  {
    id: 1,
    name: 'Venture Mentality',
    image: ventureMentalityImg,
    body: [
      'Accessesible investors with a nimble approach',
      'Valuable source of guidance with decades of strategic and venture investing experience',
      'Vast network of digital health investors and talent'
    ]
  },
  {
    id: 2,
    name: 'Corporate Platform',
    image: corporatePlatformImg,
    body: [
      'Collaborative relationships through GHI’s Strategic Innovation Alliances function',
      'Connections to Merck’s global operations and talent in more than 140 countries',
      'Access to expertise in clinical development, commercialization, supply chain, marketing and regulatory compliance'
    ]
  }
];

export default ghifDistinction;
