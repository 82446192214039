import advandxLogo from "../assets/images/logos/logo_advandx.png";
import aptusHealthLogo from "../assets/images/logos/logo_aptus_health.png";
import asuragenLogo from "../assets/images/logos/logo_asuragen.png";
import aventuraLogo from "../assets/images/logos/logo_aventura.png";
import clevelandHeartLogo from "../assets/images/logos/logo_clevelandheart.png";
import decipherLogo from "../assets/images/logos/logo_decipher.png";
import ehealthLogo from "../assets/images/logos/logo_ehealthimg.png";
import electroCareLogo from "../assets/images/logos/logo_electro.jpg";
import exostarLogo from "../assets/images/logos/logo_exostarimg.jpg";
import healthSenseLogo from "../assets/images/logos/logo_healthsense.png";
import humedicaLogo from "../assets/images/logos/logo_humedicaimg.png";
import liasonLogo from "../assets/images/logos/logo_liaisonimg.png";
import livongoHealthLogo from "../assets/images/logos/logo_livongoimg.png";
import mediFindLogo from "../assets/images/logos/logo_medifind.png";
import opGenLogo from "../assets/images/logos/logo_opgenimg.png";
import patientSafeLogo from "../assets/images/logos/logo_patientsafeimg.png";
import preventiceLogo from "../assets/images/logos/logo_preventiceimg.png";
import remedyLogo from "../assets/images/logos/logo_remedyimg.png";
import staywellLogo from "../assets/images/logos/logo_staywellimg.png";
import telerXLogo from "../assets/images/logos/logo_telerximg.png";
import virtualSciencesLogo from "../assets/images/logos/logo_virtualscopics.jpg";
import wiserTogetherLogo from "../assets/images/logos/logo_wisertogether.jpg";

const alumni = [
  {
    name: "AdvanDx",
    image: advandxLogo,
    text: "Rapid pathogen testing (Sold to OpGen)"
  },
  {
    name: "Aptus Health",
    image: aptusHealthLogo,
    text: "Multichannel engagement solutions for physicians (sold to WebMD)"
  },
  {
    name: "Asuragen",
    image: asuragenLogo,
    text: "Leading developer of molecular diagnostics: (sold to Bio-Techne)"
  },
  {
    name: "Aventura",
    image: aventuraLogo,
    text: "Smart clinical workflow (Sold to Philips)"
  },
  {
    name: "ClevelandHeartLab",
    image: clevelandHeartLogo,
    text: "Advanced cardiovascular reference lab (sold to Quest)"
  },
  {
    name: "Decipher",
    image: decipherLogo,
    text: "Genomic tests for prostate and urologic cancers (sold to Verasyte)"
  },
  {
    name: "eHealth Technologies",
    image: ehealthLogo,
    text: "Referral management solutions (sold to Aldrich Capital)"
  },
  {
    name: "ElectroCore",
    image: electroCareLogo,
    text: "Non-invasive Electroceuticals (IPO)"
  },
  {
    name: "Exostar",
    image: exostarLogo,
    text: "Enterprise data security solutions (sold to Thomo Brava)"
  },
  {
    name: "Healthsense",
    image: healthSenseLogo,
    text: "Senior care remote monitoring (sold to Great Call)"
  },
  {
    name: "Humedica",
    image: humedicaLogo,
    text: "Health care informatics (Sold to Optum)"
  },
  {
    name: "Liason",
    image: liasonLogo,
    text: "Health data interoperability solutions (sold to OpenText)"
  },
  {
    name: "Livongo Health",
    image: livongoHealthLogo,
    text: "Simplified diabetes care management (IPO)"
  },
  {
    name: "MediFind",
    text: "Sold to Phreesia July 2023",
    image: mediFindLogo
  },
  {
    name: "OpGen",
    image: opGenLogo,
    text: "Rapid diagnostics for infection control (IPO)"
  },
  {
    name: "PatientSafe Solutions",
    image: patientSafeLogo,
    text: "Clinical workflow and communications (Sold to Vocera)"
  },
  {
    name: "Preventice Solutions",
    image: preventiceLogo,
    text: "Cardiovascular remote monitoring solutions (sold to Boston Scientific)"
  },
  {
    name: "Remedy Informatics",
    image: remedyLogo,
    text: "Health care informatics (Sold to Liaison)"
  },
  {
    name: "Staywell",
    image: staywellLogo,
    text: "Health engagement solutions to improve outcomes (Sold to WebMD)"
  },
  {
    name: "TelerX",
    image: telerXLogo,
    text: "Health care customer service outsourcing (Sold to C3i Solutions)"
  },
  {
    name: "VirtualScopics",
    image: virtualSciencesLogo,
    text: "Imaging for clinical trials (Sold to Biotelemetry)"
  },
  {
    name: "Wiser Together",
    image: wiserTogetherLogo,
    text: "Patient engagement solutions (sold to eVive)"
  }
];

export default alumni;
