import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';
import PortfolioTile from '../components/PortfolioTile';
import portfolioItems from '../data/portfolio';
import styles from '../styles/Portfolio.module.scss';

const Portfolio = () => {
  return (
    <Container fluid className={styles.sectionSixWrapper} id='portfolio'>
      <h2>Partnering With Innovative Health Care Companies</h2>
      <Row className={styles.portfolioWrapper}>
        {portfolioItems.map((item) => (
          <PortfolioTile item={item} key={item.name} />
        ))}
      </Row>
      <Row className={styles.portfolioWrapperMobile}>
        <Carousel className='portfolio-carousel'>
          {portfolioItems.map((item) => (
            <Carousel.Item>
              <a
                href={item.link}
                target='_blank'
                rel='noreferrer'
                aria-label='Opens in a new tab.'
              >
                <img
                  className='d-block w-100'
                  src={item.image}
                  alt='First slide'
                />
              </a>
            </Carousel.Item>
          ))}
        </Carousel>
      </Row>
      <Row className={styles.alumniWrapper}>
        <a href='/alumni'>Click here to view our alumni portfolio</a>
      </Row>
    </Container>
  );
};

export default Portfolio;
