import React from 'react';
import styles from '../styles/Intro.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import homeImage from '../assets/images/home.jpg';

const Intro = ({ introRef }) => {
  return (
    <Container fluid className={styles.sectionOneWrapper} ref={introRef}>
      <Row>
        <Col
          lg={5}
          md={6}
          sm={12}
          className={`${styles.sectionOneTile} ${styles.imgTile}`}
        >
          <img src={homeImage} alt='Woman doctor looking at screen' />
        </Col>
        <Col
          lg={7}
          md={6}
          sm={12}
          className={`${styles.sectionOneTile} ${styles.textTile}`}
        >
          <div>
            <h1>
              Investing In Digital Health Solutions To Improve Human&nbsp;Life
            </h1>
            <p>
              Merck Global Health Innovation Fund (MGHIF) is a growth investor
              partnering with innovative digital health and data science
              companies that facilitate and optimize biopharmaceutical
              operations with the goal of improving patient care.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Intro;
