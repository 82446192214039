import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScriptInjector = () => {
  const location = useLocation();

  useEffect(() => {

    // console.log("Current path:", location.pathname);
    

    // if (!location.pathname.includes('/blockaccess')) {
    //   const script = document.createElement('script');
    //   script.textContent = `console.log('Test script injected successfully.');`;
    //   document.body.appendChild(script);
  
    //   return () => {
    //     document.body.removeChild(script);
    //   };
    // }

    // Ensure scripts are not injected on the /blockaccess page
    if (!location.pathname.includes('/blockaccess')) {
      // Define the script content based on the environment
      const scriptContent = process.env.REACT_APP_SCRIPT_VERSION === 'production'
        ? `(function(g,e,o,t,a,r,ge,tl,y){t=g.getElementsByTagName(e)[0];y=g.createElement(e);y.async=true;var a=window,b=g.documentElement,c=g.getElementsByTagName('body')[0],w=a.innerWidth||b.clientWidth||c.clientWidth,h=a.innerHeight||b.clientHeight||c.clientHeight;y.src='https://g10894638425.co/gp?id=-Nu_zHL9CQWZxgSjca-y&refurl='+g.referrer+'&winurl='+encodeURIComponent(window.location)+'&cw='+w+'&ch='+h;t.parentNode.insertBefore(y,t);})(document,'script');`
        : `(function(g,e,o,t,a,r,ge,tl,y){t=g.getElementsByTagName(e)[0];y=g.createElement(e);y.async=true;var a=window,b=g.documentElement,c=g.getElementsByTagName('body')[0],w=a.innerWidth||b.clientWidth||c.clientWidth,h=a.innerHeight||b.clientHeight||c.clientHeight;y.src='https://g10894638425.co/gp?id=-Nua-cgLHUntNeP0MyEE&refurl='+g.referrer+'&winurl='+encodeURIComponent(window.location)+'&cw='+w+'&ch='+h;t.parentNode.insertBefore(y,t);})(document,'script');`;

      // Create a script element and set its content
      const script = document.createElement('script');
      script.textContent = scriptContent;
      document.body.appendChild(script);

      // Define CloseGeoPopup function to close the popup based on the environment
      window.CloseGeoPopup = () => {
        // Determine the class name based on the environment
        const className = process.env.REACT_APP_SCRIPT_VERSION === 'production'
          ? "geotargetlygeopopup1712181814719close"
          : "geotargetlygeopopup1712182168396close";

        // Attempt to close the popup
        const closeButton = document.getElementsByClassName(className)[0];
        if (closeButton) {
          closeButton.click();
        } else {
          console.warn('Close button not found for className:', className);
        }
      };

      // Cleanup to remove the script when the component unmounts or path changes
      return () => {
        document.body.removeChild(script);
      };
    }


  }, [location.pathname]); // Rerun the effect if the pathname changes

  return null; // This component doesn't render anything
};

export default ScriptInjector;
