import React, { useRef } from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import { Outlet } from 'react-router-dom'; // Import Outlet from react-router-dom

const Layout = () => {
  const footerRef = useRef(null);

  return (
    <div>
      <Navigation />
      <Outlet /> 
      <Footer footerRef={footerRef} />
    </div>
  );
};

export default Layout;
