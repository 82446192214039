import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Modal from './Modal';
import styles from '../styles/Person.module.scss';

const Person = ({ data, currentQuery }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (data.url && data.url === currentQuery) {
      setShowModal(true);
    }
  }, [data.url, currentQuery]);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [showModal]);

  return (
    <>
      <Col
        className={styles.personTile}
        lg={3}
        md={4}
        sm={6}
        xs={6}
        onClick={() => setShowModal(true)}
        role='button'
        tabindex='0'
      >
        <div className={styles.imageWrapper}>
          <img src={data.image} alt={data.name} />
          <div className={styles.layover}></div>
          <p className={styles.layoverText}>View profile</p>
        </div>
        <div className={styles.personInfo}>
          <p className={styles.personName}>{data.name}</p>
          <p className={styles.personTitle}>{data.title}</p>
        </div>
      </Col>
      <Modal
        type='profile'
        title={data.name}
        subtitle={data.title}
        body={data.body}
        image={data.image}
        showModal={showModal}
        setShowModal={setShowModal}
        url={data.url}
      />
    </>
  );
};

export default Person;
