import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Modal from './Modal';
import styles from '../styles/InvestmentTile.module.scss';

const InvestmentTile = ({ investment, currentQuery }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (investment.url && investment.url === currentQuery) {
      setShowModal(true);
    }
  }, [investment.url, currentQuery]);

  return (
    <>
      <Col className={styles.investmentTile} lg={3} md={6} sm={12}>
        <img src={investment.image} alt='Close up of a microscope' />
        <div className={styles.layover} />
        <div className={styles.tealBox}>
          <p className={styles.tealBoxCaption}>{investment.caption}</p>
          <p className={styles.tealBoxExpand}>{investment.secondary}</p>
          <a
            className={styles.tealBoxLink}
            onClick={() => setShowModal(true)}
            role='button'
            tabindex='0'
          >
            Read more
          </a>
        </div>
      </Col>
      <Modal
        type='investment'
        title={investment.caption}
        body={investment.modalBody}
        modalListItems={investment.modalListItems}
        image={investment.modalImage}
        showModal={showModal}
        setShowModal={setShowModal}
        url={investment.url}
      />
    </>
  );
};
export default InvestmentTile;
