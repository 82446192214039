import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Person from '../components/Person';
import people from '../data/people';
import styles from '../styles/OurTeam.module.scss';

const OurTeam = ({ currentQuery }) => {
  return (
    <Container className={styles.sectionFiveWrapper} id='our-team'>
      <h2 className='ghi'>
        Bringing Venture Mentality With The Benefits Of A Strategic Investor
      </h2>
      <Row className={`${styles.peopleWrapper} gx-20`}>
        {people.map((data) => (
          <Person data={data} key={data.id} currentQuery={currentQuery} />
        ))}
      </Row>
    </Container>
  );
};

export default OurTeam;
